const mainContent: HTMLElement | null = document.getElementById('main-content');
const baseImagePath = 'assets/assets/pad_maennlis-';
const imageSuffix = '.png';
const imageIds = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
const horizontalGap = 40;

const figuresLeft: HTMLDivElement = document.createElement('div');
figuresLeft.className = 'figurines figurines--left';
const figuresRight: HTMLDivElement = document.createElement('div');
figuresRight.className = 'figurines figurines--right';

init();

function init() {
    if (!mainContent) {
        return;
    }

    mainContent?.append(figuresLeft, figuresRight);
    draw(figuresLeft);
    draw(figuresRight);
}

function draw(target: HTMLDivElement):void {
    const randomIds = [...imageIds];
    shuffle(randomIds);

    const numImages = Math.floor(target.getBoundingClientRect().height / (target.getBoundingClientRect().width + horizontalGap));
    for(let i = 0; i < numImages - 1; i++) {
        const img = document.createElement('img');
        img.src = `${baseImagePath}${randomIds[i]}${imageSuffix}`;
        target.append(img);
    }
}

function shuffle(array) {
    array.sort(() => Math.random() - 0.5);
}